.landingpage {

  #logo {
    color: #6e3cc3;
    img {
      padding-right: 5px;
    }
  }

  .main-box {
    background-position: center top;
    background-repeat: no-repeat;
    position: relative;
    color: #000;
    text-align: center;
    min-height: 970px;
    background: url("https://quickt.de/background.png");
    background-size: cover;



    .box {
      width: 100%;
      font-size: 16px;
      padding-top: 250px;
      .text {
        border-radius: 6px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        background-color: #fff;
        padding: 15px;
        display: inline-grid;
      }
    }
  }


.color1 {
    color: #d54b63;
}
.color2 {
    color: #aa4575;
}

}

