.multi-select {
  position: relative;
  width: 100%;

  &__container {
    min-height: 38px;
    padding: 0.25rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    cursor: text;
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    background: #fff;

    &:focus-within {
      border-color: #2563eb;
      box-shadow: 0 0 0 1px #2563eb;
    }
  }

  &__tag {
    background-color: #f3f4f6;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.875rem;

    button {
      margin-left: 0.25rem;
      color: #6b7280;
      border: none;
      background: none;
      padding: 0;
      cursor: pointer;
      font-size: 1rem;
      line-height: 1;

      &:hover {
        color: #374151;
      }
    }
  }

  &__input {
    flex: 1;
    min-width: 60px;
    height: 28px;
    border: none;
    outline: none;
    font-size: 0.875rem;

    &::placeholder {
      color: #9ca3af;
    }
  }

  &__dropdown {
    position: absolute;
    width: 100%;
    margin-top: 0.25rem;
    max-height: 15rem;
    overflow-y: auto;
    background: white;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    z-index: 10;

    &-empty {
      padding: 0.5rem;
      color: #6b7280;
      font-size: 0.875rem;
    }

    &-option {
      padding: 0.5rem 0.75rem;
      font-size: 0.875rem;
      cursor: pointer;

      &:hover {
        background-color: #f3f4f6;
      }
    }
  }
}