body {

}

#logo {
  color: #6e3cc3;
  img {
    padding-right: 5px;
  }
}


.display-board {
  width: 100%;
  background-color: rgb(555, 200, 789);
  padding: 5%;
}

.number {
  color: red;
  font-size: 75px;
  text-align: center;
}

.margin-10 {
  margin: 10px;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.float-right {
  float: right;
}

label.required:before {
  content: "*";
  color: red;
}

.modal-footer {
  .dw-buttons {
    margin: 0
  }
}

.new-project-text {
  display: block;
  width: 100%;
  margin: 80px 0;
  text-align: center;
  font-size: 30px;
}