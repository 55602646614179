

$pagination-active-bg: #6e3cc3 !default;
$pagination-color: #000 !default;
$pagination-bg: #ffffff !default;
$pagination-border-color: #ccc !default;

$pagination-hover-color: #000 !default;
$pagination-hover-bg: #ccc !default;
$pagination-hover-border-color: #ccc !default;

$pagination-active-color: #ffffff !default;
$pagination-active-bg: #6e3cc3 !default;
$pagination-active-border-color: #6e3cc3 !default;

$pagination-disabled-color: #ccc !default;
$pagination-disabled-bg: #ffffff !default;
$pagination-disabled-border-color: #ccc !default;

$primary: #6e3cc3;

$navbar-light-color: #26064a;
$navbar-light-hover-color: rgba($navbar-light-color, .7);
$navbar-light-active-color: rgba($navbar-light-color, .9);

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

/*$theme-colors: (
        primary: #005d6c,
        info: #d10505,
        danger: #d10505,
        link-primary: #005d6c,
        text-primary: #005d6c,
        btn-primary: #005d6c,
        btn-link: #005d6c
);*/





@import 'bootstrap/scss/bootstrap';
@import "../node_modules/flag-icons/css/flag-icons.min.css";



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

.loading-icon {
  animation: animate 30s infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
