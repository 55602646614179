.api-documentation {

  #logo {
    color: #6e3cc3;
    img {
      padding-right: 5px;
    }
  }

  .swagger-ui .info {
    margin: 15px 0;
  }

}

