.quickt__table {

  table {
    tr {
      line-height: 25px;
    }

    td {
      word-break: break-all;
    }
  }

  .header-filter {
    display: none;
  }

  @media (max-width: 600px) {
    .header-filter {
      float: right;
      display: block;
    }
    /*th {
      font-size: 12px;
    }*/
    .filter-row {
      display: none !important;
    }
  }

  .filter-icon {
    float: right;
  }


  .card {
    .card-header {
      display: flex;
      justify-content: space-between;
      padding: 5px 5px;
    }

    .card-body {
      padding: 0 10px;
    }
  }

}
