.dw-buttons {
    display: inline;

    button {

        margin: 1px 2px;

        .icon {
            position: relative;
            top: -2px;
            padding-right: 3px;
        }

        .rounded-pill {
            font-size: 11px;
        }

    }

    @media (max-width: 840px) {
        .btn .btn-text {
            display: none;
            padding: 5px;
        }
    }

}
